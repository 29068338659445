import { Component, Vue } from 'vue-property-decorator';
import vSelect from 'vue-select';
import Newsletter from '@/modules/FooterModule/Newsletter/Newsletter.vue';
import Phone from '@/assets/icons/phone.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';
import Arrow from '@/assets/icons/arrow.svg?inline';
import Facebook from '@/assets/icons/facebook.svg?inline';
import Twitter from '@/assets/icons/twitter.svg?inline';
import Instagram from '@/assets/icons/instagram.svg?inline';
import Youtube from '@/assets/icons/youtube.svg?inline';
import Pinterest from '@/assets/icons/pinterest.svg?inline';
import Linkedin from '@/assets/icons/linkedin.svg?inline';
import { getComponentProps } from '@/utils/vueUtils';
import { FooterModuleViewModel } from './FooterModule.types';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { openConsentManager } from '@/plugins/utm/SegmentConsentManager';
import { getResizeImage } from '@/utils/commonUtils';
import { fixDropdownAudioEye } from '@/utils/domUtils';
import { getStore } from '@/store';
import BlackCobrandLogo from '@/assets/icons/black-cobrand-logo.svg?inline';
import SingleBrandLogo from '@/assets/icons/single-brand-logo.svg?inline';
import { Country } from '@/store/modules/metadataModule';

const footerProps = Vue.extend({
  props: getComponentProps<FooterModuleViewModel>({
    BackgroundImage: {
      Url: getResizeImage('footer-background.jpg'),
      Alt: 'Background',
    },
    Logo: {
      Url: require('@/assets/icons/logo.svg'),
      Alt: 'Logo',
    },
    PhoneCta: {
      Header: 'Talk to an experience specialist',
      SecondHeader: '1.800.397.3348',
      ButtonsLarge:  [{
        Title: 'Call now',
        Link: '18003973348',
        Action: 'Phone',
        ButtonStyle: CtaButtonStyle.White,
      }],
      ButtonsSmall:  [{
        Title: 'Call now',
        Link: '18003973348',
        Action: 'Phone',
        ButtonStyle: CtaButtonStyle.White,
      }],
    },
    CopyrightString: '2020 Lindblad expeditions',
    CountrySwitcher: {
      Placeholder: 'Country',
      CustomiseClass: 'vs--yellow',
      Countries: [
        { Title: 'Australia', Link: '#' },
        { Title: 'US', Link: '#' },
        { Title: 'World', Link: '#' },
      ],
    },
    FooterGroupedNavigationLinks: [
      {
        IsOpenOnMobile: false,
        Type: '',
        Title: 'Find an Expedition',
        Link: { Title: '', Link: '' },
        Links: [
          { Title: 'Browse by Destination', Link: '/example/page' },
          { Title: 'Browse by Duration', Link: '/example/page' },
          { Title: 'Browse by Date', Link: '/example/page' },
        ],
      },
    ],
    FooterBottomNavigationLinks: [
      {
        Title: 'Privacy Policy',
        Link: '/example/page',
      },
    ],
    FooterSocialNavigationLinks: [
      {
        Icon: 'Facebook',
        Url: 'https://www.facebook.com/LindbladExpeditions/',
      },
    ],
  }),
});

@Component({
  components: {
    Newsletter,
    vSelect,
    Phone,
    Cross,
    Arrow,
    Facebook,
    Pinterest,
    Twitter,
    Instagram,
    Youtube,
    Linkedin,
    CtaButton,
    ResponsiveImage,
    BlackCobrandLogo,
    SingleBrandLogo,
  },
})
export default class FooterModule extends footerProps {
  countrySelected(item) {
    window.location.href = item.Link;
  }

  get ButtonsLarge() {
    return this.p.PhoneCta.ButtonsLarge
      .filter(p => p.Link)
      .map(p => ({
        ...p,
        Link: p.Title.includes('Australia') ? p.Link.replace(/\+/g, '') : p.Link
      }))
  }
  get ButtonsSmall() {
    return this.p.PhoneCta.ButtonsSmall
      .map(p => ({
        ...p,
        Link: p.Title.includes('Australia') ? p.Link.replace(/\+/g, '') : p.Link
      }))
  }

  get PhoneHeader() {
    const phone = this.p.PhoneCta.SecondHeader;
    if (phone) {
      const index = phone.indexOf('(');
      return {
        First: phone.substring(0, index),
        Second: phone.substring(index + 1).replace(')', ''),
      };
    }
  }
  toggleAccordion($event: Event) {
    const target = $event.target as HTMLButtonElement
    target.classList.toggle('footer__accordion-toggle_checked')
  }
  openManager() {
    openConsentManager()
  }

  mounted() {
    const langEl = (this.$refs.lang as any).$el
    fixDropdownAudioEye(langEl, this.p.CountrySwitcher.Countries, false)
  }

  fixUlOnClose() {
    const langEl = (this.$refs.lang as any).$el
    setTimeout(() => {
      fixDropdownAudioEye(langEl, this.p.CountrySwitcher.Countries, false)
    }, 500);
  }

  get ContactHours() {
    const navItems = getStore().state.Modules.PageData.Slots[0].Modules[0].Data.Items
    const navItemWithContact = navItems.find(i => i.Title === 'Contact')
    const contactItem = navItemWithContact.Items.find(n => n.Title === 'Talk to an Expedition Specialist')
    return contactItem.Subtitle
  }

  get isUSALocale() {
    const countryId = getStore().state.Meta.Country
    return countryId === Country.US 
  }
}
