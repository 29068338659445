import { Component, Vue, Prop } from 'vue-property-decorator';
import { NewsletterViewModel } from './Newsletter.types';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
@Component({
  components: {
    CtaButton,
  }
})

export default class Newsletter extends Vue {
  @Prop({ type: Object as () => NewsletterViewModel, default: {} })
  readonly Newsletter!: NewsletterViewModel;

  get newsLetterLink() {
    return `https://${window.location.host}/newsletter-sign-up/`;
  }
}
